@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .underline {
    @apply w-[75px] h-[3px] bg-colorOrange;
  }
}

.popup-overlay {
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure overlay is on top */
}

.popup-for-syllabus {
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure overlay is on top */
}

.arrow {
  @apply absolute right-0 top-0 h-full w-0;
  transition: transform 0.3s ease-in-out; /* Animate the icon's rotation */
}

.explore-us-button {
  @apply bg-blue-500 text-white px-4 py-2 rounded-md;
  position: relative;
}

.arrow {
  @apply absolute right-0 top-0 h-full w-0;
  transition: transform 0.3s ease-in-out;
}

.explore-us-button:hover .arrow {
  @apply transform rotate-90;
}